<template>
  <div id="thermal">
    <div class="page" v-for="page in pages" :key="'page' + page">
      <div class="watermark" v-if="sale.status == 'draft'">
        RASCUNHO
      </div>
      <v-row class="pt-4 justify-center">
        <v-col class="text-center" cols="3">
          <v-avatar size="65">
            <img src="https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png" />
          </v-avatar>
        </v-col>
        <v-col style="line-height: 12pt">
          <b style="font-size: 10px">Rede Casas das Tintas</b>
          <template v-if="hasAddress">
            <div>
              {{ sale.company.main_address.address }}
              {{ sale.company.main_address.number }},
              {{ sale.company.main_address.district }}
            </div>
            <div>
              {{ sale.company.main_address.city }} -
              {{ sale.company.main_address.state }},
              {{ $format.cep(sale.company.main_address.postal_code) }},
            </div>
          </template>
          <template v-if="hasPhones">
            <div class="d-flex justify-space-around">
              <div class="text-center" v-for="(phone, index) in phones" :key="index">
                <span>{{ phone }}</span>
                <span v-if="phones.length > index + 1"></span>
              </div>
            </div>
          </template>
        </v-col>
      </v-row>

      <hr class="mt-3 mb-4" />

      <v-row class="d-flex justify-space-between align-center mb-2">
        <v-col cols="12">
          <b>Dados Do Cliente</b>

          <div v-if="hasCustomer">
            <span>
              <b>Nome:</b> {{ sale.customer.code }} -{{ sale.customer.name }}
            </span>
            <br />
            <span v-if="docNumber">
              <b>{{ docLabel }} </b>{{ $format.hide(docNumber, { end: 6 }) }}
            </span>
            <span class="mr-2" v-if="sale.customer.phone_wa">
              WhatsApp: {{ sale.customer.phone_wa }}
            </span>
            <app-show-phones class="d-flex" style="justify-content: space-between" :phones="sale.customer.phones" />
            <span>
              <b>Endereço: </b>
              {{ sale.customer.main_address.address }},
              {{ sale.customer.main_address.number }},
              {{ sale.customer.main_address.district }}
              <br />
              {{ sale.customer.main_address.city }}-
              {{ sale.customer.main_address.state }},
              {{ $format.cep(sale.customer.main_address.postal_code) }}
              <span v-if="sale.customer.main_address.complement">
                <br />
                {{ sale.customer.main_address.complement }}
              </span>
            </span>
          </div>

          <div v-else>
            <span> <b>Nome: </b> Consumidor Final </span>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col class="d-flex flex-column">
          <b>Operação: {{ operation }} N° {{ sale.number }}</b>
          <b> Emissão: {{ $format.dateTimeBr(date) }}</b>
          <b>
            Vendedor: {{ sale.salesman.code }} -{{ sale.salesman.short_name }}
          </b>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :page="page" :headers="headers" :items="getItems(page)" :items-per-page="-1"
            hide-default-footer disable-sort dense>
            <template v-slot:[`item.product`]="{ item }">
              <template v-if="item.product">
                <b>
                  {{ item.product_company.company.code }} -
                  {{ item.product.code }} - {{ item.product.name }}
                </b>
                <div>
                  <b class="mr-2">
                    QT: {{ $format.decimal(item.quantity) }}
                    <span v-if="item.product_variant.unity">
                      {{ item.product_variant.unity.symbol }}
                    </span>
                  </b>
                  <b>PR : {{ $format.moneyBr(item.price) }} </b>
                </div>
              </template>
            </template>

            <template v-slot:[`item.quantity`]="{ item }">
              <span v-if="item.product">
                {{ $format.decimal(item.quantity) }}
                {{ item.product_variant.unity.symbol }}
              </span>
            </template>

            <template v-slot:[`item.price`]="{ item }">
              <span v-if="item.product">
                {{ $format.decimal(item.price) }}
              </span>
            </template>

            <template v-slot:[`item.net_total`]="{ item }">
              <span v-if="item.product">
                {{ $format.decimal(item.net_total) }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="sale.delivery_type == 'delivery'" class="mb-4">
        <v-col cols="12">
          O prazo de entrega padrão é de até 30 dias a partir da data da compra.
          Caso exceda esse prazo a entrega dependerá da disponibilidade do
          estoque e programação logística.
        </v-col>
        <v-col class="text-center">
          <br />
          <u>
            As entregas de cimento serão realizadas exclusivamente no térreo.
          </u>
        </v-col>
      </v-row>
      <v-row style="word-break: break-word" v-if="page == 1">
        <v-col>
          <span>Observação:</span>
        </v-col>
      </v-row>
      <template v-if="page == pages">
        <v-row>
          <v-col class="d-flex justify-space-around">
            <span v-for="(bill, index) in sale.sale_payment_terms" :key="index">
              {{ bill.payment_term.name }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex flex-column text-right mt-4">
            <span>
              <b class="mr-1">Sub-Total: </b>
              {{ $format.moneyBr(sale.product_total) }}
            </span>

            <span>
              <b class="mr-1">Desconto:</b>
              {{ $format.moneyBr(sale.general_discount) }}
            </span>

            <span>
              <b class="mr-1"> Total:</b>
              {{ $format.moneyBr(sale.net_total) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center text-center pb-5">
          <v-col>
            <v-divider style="border: 0.1px solid black; margin-top: 30px" />
            Assinatura
            <div class="align-baseline mt-2">
              <b>Não é documento fiscal</b>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-if="sale.status == 'proposal'">
        <div class="text-center my-5">
          <b>** Orçamento de venda (válido por 7 dias) **</b>
        </div>
        .
      </template>
    </div>
  </div>
</template>

<script>
import AppShowPhones from "@/components/app/sections/AppShowPhones.vue";
import PaymentMethodSpan from "@/components/payment-terms/ui/PaymentMethodSpan.vue";
export default {
  components: { AppShowPhones, PaymentMethodSpan },

  props: {
    sale: {},
    hasAddress: {},
    hasPhones: {},
    phones: {},
  },

  data() {
    return {
      headers: [
        { text: "Produto", value: "product", width: "90%" },

        // {
        //   text: "Qtd",
        //   value: "quantity",
        //   align: "center",
        //   width: "5%",
        // },
        // { text: "Preço", value: "price", align: "center", width: "10%" },
        { text: "Total", value: "net_total", align: "center" },
      ],

      status: {
        sale: "Venda",
        proposal: "Orçamento",
        draft: "Rascunho",
      },
    };
  },

  computed: {
    operation() {
      if (this.sale.operation_id == 1) {
        return this.status[this.sale.status];
      } else {
        return this.sale.operation.description;
      }

      // return this.sale.operation;
      //       <span v-if="sale.operation_id == 1">
      //   {{ status[sale.status] }}
      // </span>
      // <span v-if="sale.operation_id == 2">
      //   {{ sale.operation.description }}
      // </span>
    },
    itensPerPage() {
      return this.itensCount;
    },
    hasCustomer() {
      return this.sale.customer_id;
    },

    personType() {
      return this.sale.customer.person_type;
    },
    docLabel() {
      return this.personType == "juridica" ? "CNPJ:" : "CPF:";
    },
    docNumber() {
      return this.personType == "juridica"
        ? this.$format.cnpj(this.sale.customer.cnpj)
        : this.$format.cpf(this.sale.customer.cpf);
    },

    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    sale_products() {
      return this.sale.sale_products;
    },
    itensCount() {
      if (this.sale_products) {
        return this.sale_products.length;
      }
      return 1;
    },

    date() {
      var statues = {
        canceled: "canceled_at",
        sale: "sold_at",
        order: "ordered_at",
        proposal: "proposed_at",
        draft: "drafted_at",
      };
      return this.sale[statues[this.sale.status]] ?? this.sale.saved_at;
    },
  },

  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },

    getItems(page) {
      let items = this.sale_products.slice(
        (page - 1) * this.itensPerPage,
        page * this.itensPerPage
      );
      if (items.length < this.itensPerPage) {
        for (let i = items.length; i < this.itensPerPage; i++) {
          items.push({});
        }
      }
      return items;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/prints/ThermalPrinting.scss";
</style>
